import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import NavButton from "../nav-button";
import { AppSettingsContext } from "../../App";
import styles from "./navbar.module.scss";
import {
  ULTIMATE_FOUNDER_WINNERS,
  PAST_WINNERS,
  DOWNLOAD_ASSETS,
  NOMINATE,
  VOTE,
  ASSETS,
  GLOBAL_ACTIVATION,
  // PURPOSE,
  // DOWNLOAD,
  // ALL_STORIES,
  // LIKED_STORIES_NAV,
  COMMITTEE_STORIES_NAV,
} from "../../constants/common";

const NavButtonLike = ({ label, onClick }) => {
  return (
    <div className={styles.navButtonLikeWrap} onClick={onClick}>
      <div className={styles.navButtonLike}>{label}</div>
    </div>
  );
};

const NavbarLinks = ({
  sitePhase,
  newsletterSent,
  downloadLink,
  likedStoriesLength,
  onFooter,
}) => {
  const { showCommitteeStories } = useContext(AppSettingsContext);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const scrollToGlobalActivationBoxes = () => {
    const hereParagraph = document.getElementById("global_activation_boxes");
    if (hereParagraph) {
      hereParagraph.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={styles.buttonsWrapper}>
      {/* NOMITATION OR VOTING BUTTON */}
      {(() => {
        switch (sitePhase) {
          case "Nomination":
            return <NavButton label={NOMINATE} to="/share-your-story" />;
          case "Voting":
            return <NavButton label={VOTE} to="/vote-for-ultimate-founders" />;
          default:
            return null;
        }
      })()}

      {/* ULTIMATE_FOUNDER_WINNERS OR VOTING BUTTON */}
      {sitePhase === "Choosing" && (
        <NavButton
          label={DOWNLOAD_ASSETS}
          to="https://bacardi.sharepoint.com/sites/Founder'sDay2024/Founders%20Day%202025%20Assets/Forms/GALLERY.aspx"
          target="_blank"
        />
      )}

      {/* ULTIMATE_FOUNDER_WINNERS OR VOTING BUTTON */}
      {(() => {
        switch (sitePhase) {
          case "Selection":
            return (
              <NavButton
                label={ULTIMATE_FOUNDER_WINNERS}
                to="/ultimate-founders-winners"
              />
            );

          default:
            if (sitePhase !== "Voting" || onFooter) {
              return <NavButton label={PAST_WINNERS} to="/past-winners" />;
            }
        }
      })()}

      {isHomePage && sitePhase === "Selection" && (
        <NavButtonLike
          label={GLOBAL_ACTIVATION}
          onClick={scrollToGlobalActivationBoxes}
        />
      )}

      {sitePhase !== "Selection" && newsletterSent && (
        <NavButton
          label={ASSETS}
          to="https://bacardi.sharepoint.com/sites/FoundersDay2024ToolKits"
          download
        />
      )}

      {/* <NavButton label={ALL_STORIES} to="/stories" /> */}
      {/* {!!likedStoriesLength && <NavButton label={LIKED_STORIES_NAV} to="/liked-stories" />} */}
      {showCommitteeStories && (
        <NavButton label={COMMITTEE_STORIES_NAV} to="/committee-stories" />
      )}

      {/* <NavButton label={PURPOSE} to="/purpose" /> */}
      {/* <NavButton label={DOWNLOAD} to={downloadLink} download /> */}
    </div>
  );
};

export default NavbarLinks;
