import React from "react";
import "./no-stories-info.scss";

const NoStoriesInfo = ({ title, message }) => {
  return (
    <div className="bc-no-stories-info">
      {title}
      {message}
    </div>
  );
};

NoStoriesInfo.defaultProps = {
  title: <div className="title">Ooops..</div>,
  message: (
    <p>
      Unfortunately, there are no stories from this country.
      <br />
      Please change your search criteria to view available stories.
    </p>
  ),
};

// NoStoriesInfo.propTypes = {
//   stories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   title: PropTypes.node,
//   message: PropTypes.node
// };

export default NoStoriesInfo;
