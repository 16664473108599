import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { Col, Row } from "react-flexbox-grid";

import Thumbnail from "../best-story-item";
import NoStoriesInfo from "../../../components/no-stories-info";

import styles from "./best-stories-content.module.scss";

const ThumbnailsList = ({
  items,
  hasNext,
  getNext,
  simplified,
  accent,
  onClick,
  onLiking,
  showTags,
}) => {
  const [_items, setLst] = useState(items);
  const [_hasNext, setHasNext] = useState(hasNext);

  const navigate = useNavigate();

  const handleClick = () => {
    getNext(({ nextItems, hasNextItems }) => {
      setHasNext(hasNextItems);
      if (nextItems?.length) {
        setLst((prev) => [...prev, ...nextItems]);
      }
    });
  };
  return (
    <Row center="xs">
      <Col xs={12}>
        {_items?.length ? (
          <div className={styles.list}>
            {_items.map((item) => (
              <Thumbnail
                key={item.id}
                data={item}
                accent={accent}
                showTags={showTags}
                simplified={simplified}
                onLiking={onLiking}
                onClick={() => {
                  if (onClick) {
                    onClick(item);
                  } else {
                    navigate(`/locations/${item.location_slug}/${item.slug}`);
                  }
                }}
              />
            ))}
          </div>
        ) : (
          <NoStoriesInfo
            title={null}
            message={
              <p>Unfortunately, there are no stories from this category</p>
            }
          />
        )}
      </Col>

      {hasNext && (
        <Col xs={12}>
          <button onClick={handleClick}>CLICKKKK</button>
        </Col>
      )}
    </Row>
  );
};

ThumbnailsList.defaultProps = {
  simplified: false,
  accent: "purple",
  onClick: undefined,
  showTags: false,
};

ThumbnailsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  simplified: PropTypes.bool,
  accent: PropTypes.oneOf(["purple", "green"]),
  onClick: PropTypes.func,
  showTags: PropTypes.bool,
};

export default ThumbnailsList;
