import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { apolloClient } from "../../../index";

import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

import { useNavigate } from "react-router-dom";
import { gql, useApolloClient } from "@apollo/client";
import Loader from "../../../components/loader";
import { maxThumbsNbr } from "../../../serverConfig";
import Button from "../../../components/button";
import styles from "../best-stories-winners/best-stories-winners.module.scss";
// Components
import Navbar from "../../../components/navbar";
import Support from "../../../components/support";
import Copyright from "../../../components/copyright/copyright";
import Footer from "../../../components/footer";
import BestStoriesTabs from "../best-stories-tabs";
// import ThumbnailsList from '../best-stories-content';
import Thumbnail from "../best-story-item";
import NoStoriesInfo from "../../../components/no-stories-info";

// Styles
import "./best-stories.scss";

import {
  BEST_STORIES_DESCRIPTION,
  ULTIMATE_FOUNDERS_SHORTLIST,
  ULTIMATE_FOUNDERS,
} from "../../../constants/common";
// import sortStringBy from '../../../helpers/common';
import { useIntersect } from "../../../helpers/hooks";
import { AnimatedContent } from "../../../components/animated-content";

const MAX_STORY_NUMBER = 9999999;
const QUERY_STORIES_TAGS = gql`
query GetStoriesTags($filter:StoryFiltersInput!) {
  stories(
    pagination: {limit:${MAX_STORY_NUMBER}}
    filters:$filter
    ) {
    data {
      id
      attributes {
        tags {data {id attributes {name}}}
      }
    }
  }
}
`;

const GET_ULTIMATE_TAGS = gql`
  query getTags {
    preference {
      data {
        attributes {
          ultimate_tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          ultimate_founder_tag {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const NEW_STORIES_QUERY = gql`
query GetAllStories($filter:StoryFiltersInput!, $page: Int = 1, $pageSize: Int = ${maxThumbsNbr}) {
  stories(pagination: { page: $page, pageSize: $pageSize } 
    filters:$filter     
    sort: "nomination_year:desc") {
    data {
      id
      attributes {
        nomination_year
        author_name
        nominated_primo_name
        cover_image{data{attributes{url}}}
        title
        text
        likes_number
        location {data {id attributes{name}}}
        tags {data {id attributes {name}}}
      }
    }
    meta {
      pagination {
        total
        pageCount
        page
        pageSize
      }
    }
  }
}
`;

const getNext = async (activeTagId, nominationYear, page) => {
  try {
    let result;

    result = await apolloClient.query({
      query: NEW_STORIES_QUERY,
      variables: {
        page: page,
        pageSize: 24, // maxThumbsNbr ?? 12,
        filter: {
          and: [
            { tags: { id: { eq: String(activeTagId) } } },
            { nomination_year: { eq: parseInt(nominationYear, 10) } },
          ],
        },
      },
    });

    if (result?.error) {
      throw result?.error;
    }

    //else
    let stories = result?.data?.stories?.data;

    stories = stories
      ? stories.map((x) => {
          return {
            id: x.id,
            nominated_primo_name: x.attributes?.nominated_primo_name, //TODO
            cover_image: x.attributes?.cover_image?.data?.attributes?.url,
            title: x.attributes?.title,
            text: x.attributes?.text,
            location: x.attributes?.location?.data?.attributes?.name,
            likes: x.attributes?.likes_number,
            liked: false, //TODO
            tags: x.attributes?.tags?.data?.map((t) => t.attributes?.name),
          };
        })
      : [];

    //pagination
    const pagination = result?.data?.stories?.meta.pagination;

    //check if more pages to load exists
    const nextPage =
      pagination.page < pagination.pageCount ? pagination.page + 1 : null;

    return { pgStories: stories, nextPg: nextPage };
    // callback(stories, nextPage);
  } catch (error) {
    throw error;
  }
};

const StoriesList = ({ activeTagId, nominationYear }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stories, setStories] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const page = +searchParams.get("page");

  const handleItemClick = useCallback((itemId) => {
    if (itemId) {
      navigate(`/stories/${itemId}`);
    }
  }, []);

  const fetchNextPage = async () => {
    if (nextPage === null) return; //no page to load
    //else
    setLoading(true);
    try {
      const { pgStories, nextPg } = await getNext(
        activeTagId,
        nominationYear,
        nextPage
      );

      if (stories?.length) {
        setStories((prev) => [...prev, ...pgStories]);
      } else {
        setStories(pgStories); //First page
      }
      setNextPage(nextPg);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFirstPage = async () => {
      setNextPage(1);
      setLoading(true);
      try {
        const { pgStories, nextPg } = await getNext(
          activeTagId,
          nominationYear,
          1
        );
        setStories(pgStories); //First page
        setNextPage(nextPg);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFirstPage(); //loade page 0
  }, [activeTagId, nominationYear]);

  if (!stories || loading) {
    return <div style={{ margin: "20px 0", color: "#fff" }}>Loading...</div>; //<Loader show />;
  }
  if (error) {
    //TODO LOG
    // navigate("/error");
    return <div>{error}</div>;
  }

  return (
    <Row center="xs">
      <Col xs={12}>
        {stories?.length ? (
          <div className="best-stories__list">
            {stories.map((item) => (
              <Thumbnail
                key={item.id}
                data={item}
                onClick={() => handleItemClick(item.id)}
                // accent={accent}
                // simplified={simplified}
                showTags
              />
            ))}
          </div>
        ) : (
          <NoStoriesInfo
            title={null}
            message={
              <p>Unfortunately, there are no stories from this category</p>
            }
          />
        )}
      </Col>

      {nextPage !== null && (
        <Col xs={8} md={5} lg={3}>
          {nextPage !== null && (
            <Button
              classNames={styles.moreContentButton}
              onClick={fetchNextPage}
            >
              Load more
            </Button>
          )}
        </Col>
      )}
    </Row>
  );
};

const GetUltimateTags = async () => {
  try {
    const result = await apolloClient.query({ query: GET_ULTIMATE_TAGS });
    const _tags =
      result?.data?.preference?.data?.attributes?.ultimate_tags?.data?.map(
        (x) => ({ value: x.id, label: x.attributes?.name })
      );
    return _tags;
  } catch (error) {
    //handle error
    console.error(error.message);
    return null;
  }
};

export const BestStories = ({ globalSettings }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const client = useApolloClient();
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState(null);
  // const [activeTag, setActiveTag] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    GetUltimateTags()
      .then((tags) => {
        if (tags?.length) {
          const _tags = tags.map((x) => ({ id: x?.value, name: x?.label }));
          setTags(_tags);
          // setActiveTag(_tags[0].id);
        }
      })
      .catch((error) => {
        console.error(error.message); // handle error
      })
      .finally(() => setLoading(false));
  }, []);

  const [observerEntry, entryRef] = useIntersect({
    threshold: "0.9, 1",
    rootMargin: "-20px 0px 0px 0px",
  });

  ////

  if (!globalSettings?.nomination_year || loading) {
    return <Loader show />;
  }
  if (error || !tags) {
    return <div>{error}</div>;
  }

  return (
    <div className="best-stories__background">
      <div
        className={clsx("best-stories__container", {
          mobile: isMobileDevice(),
        })}
      >
        <Navbar />
        {/* <BestStoriesNavbar
          minimized={
            observerEntry.isIntersecting !== undefined &&
            !observerEntry.isIntersecting
          }
        /> */}

        <Grid>
          <Row center="xs">
            <AnimatedContent>
              <div className="title" ref={entryRef}>
                {ULTIMATE_FOUNDERS}
              </div>
            </AnimatedContent>
          </Row>
          <Row center="xs">
            <Col>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="ufcTextWrapper">
                  <div className="textTitle">Time to vote!</div>
                  <p className="ufcText">
                    Discover the finalists for the Ultimate Founders Competition
                    and cast your votes.
                  </p>
                  <p className="ufcText textNote">
                    Note: Voting is limited to{" "}
                    <span className="textEmph">one</span> vote per culture
                    pillar. Make sure to scroll down to explore them all!
                    <br />
                  </p>
                </div>
              </AnimatedContent>
            </Col>
          </Row>

          {tags?.map((tag, i) => (
            <div key={tag.id}>
              <AnimatedContent custom={1} delay={0.1}>
                <div className="periodLabel">{`Pillar ${i + 1}: ${
                  tag.name
                }`}</div>
              </AnimatedContent>
              <StoriesList
                activeTagId={tag.id}
                nominationYear={globalSettings?.nomination_year}
              />
            </div>
          ))}
        </Grid>
      </div>
      <Footer>
        <Support />
        <Copyright />
      </Footer>
    </div>
  );
};
